import DayOfWeekWithYear from "components/calendar/DayOfWeekWithYearBar";

export default function OneCardPage({ CardComponent }) {
  return (
    <div className="mpp-today one-card-page">
      <div className="container">
        <div className="title">
          <DayOfWeekWithYear />
        </div>
        <div className="card-container">
          <CardComponent />
        </div>
      </div>
    </div>
  );
}
