/*
 *
 */

import React, { useContext } from "react";
import { DateContext } from "App.js";

export default function Calendar() {
  return (
    <div className="calendar-wrapper">
      <div className="calendar">
        <DayNames />
        <DayNumbers />
      </div>
    </div>
  );

  function DayNames() {
    return (
      <ul className="day-names">
        <li>Sun</li>
        <li>Mon</li>
        <li>Tue</li>
        <li>Wed</li>
        <li>Thu</li>
        <li>Fri</li>
        <li>Sat</li>
      </ul>
    );
  }

  function DayNumbers() {
    const date = useContext(DateContext);
    // Create linear array of day objects.
    // Each day object contains a dayNumber and a status.
    // The status is "current" (this month), "inactive" (for other months) , or "today" (today)
    function buildDaysArray() {
      const year = date.getFullYear(),
        month = date.getMonth(),
        day = date.getDate(),
        lastDate = new Date(year, month + 1, 0).getDate(),
        firstDay = new Date(year, month, 1).getDay(),
        lastDay = new Date(year, month, lastDate).getDay();

      let daysArray = [];

      // Add the last days of the previous month
      const lastDateofLastMonth = new Date(year, month, 0).getDate();
      for (let i = firstDay; i > 0; i--) {
        const dayNumber = lastDateofLastMonth - i + 1,
          key = -100 + i; // avoid dups with current month
        daysArray.push(
          <DayNumber
            key={key}
            index={key}
            dayNumber={dayNumber}
            status="last-month"
          />
        );
      }

      // Add all days of the current month
      for (let i = 1; i <= lastDate; i++) {
        // Is the day today? Capture status accordingly
        const status = i === day ? "current-month today" : "current month";
        daysArray.push(
          <DayNumber key={i} index={i} dayNumber={i} status={status} />
        );
      }

      // Add the first days of the next month
      for (let i = lastDay; i < 6; i++) {
        const dayNumber = i - lastDay + 1,
          key = 100 + i; // avoid dups with current month
        daysArray.push(
          <DayNumber
            key={key}
            index={key}
            dayNumber={dayNumber}
            status="next-month"
          />
        );
      }

      return daysArray;
    }

    return <ul className="days">{buildDaysArray()}</ul>;
  }

  function DayNumber({ index, dayNumber, status }) {
    return (
      <li key={index} className={status}>
        {dayNumber}
      </li>
    );
  }
}
