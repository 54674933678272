import React, { useState, useEffect } from "react";

const AutoRefreshWrapper = ({ children, refreshInterval = 60000 }) => {
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshTrigger((prev) => prev + 1);
    }, refreshInterval);

    return () => clearInterval(intervalId);
  }, [refreshInterval]);

  return React.Children.map(children, (child) =>
    React.cloneElement(child, { refreshTrigger })
  );
};

export default AutoRefreshWrapper;
