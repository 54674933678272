import { Link } from "react-router-dom";

export default function Home() {
  return (
    <div className="mpp-today home">
      <div className="bar">MPP Today</div>
      <div className="subtitle">Which view would you like to explore?</div>
      <div className="options">
        <ul className="category">
          <li>
            Four Card Displays
            <ul>
              <li>
                <Link to="four-card-page">Default</Link>
              </li>
            </ul>
          </li>
          <li>
            Two Card Displays
            <ul>
              <li>
                <Link to="two-card-page">Default</Link>
              </li>
            </ul>
          </li>
          <li>
            Cards
            <ul>
              <li>
                <Link to="one-card-page/calendar">Calendar</Link>
              </li>
              <li>
                <Link to="one-card-page/clock">Clock</Link>
              </li>
              <li>
                <Link to="one-card-page/events">Events</Link>
              </li>
              <li>
                <Link to="one-card-page/Photos">Photos</Link>
              </li>
              <li>
                <Link to="one-card-page/schedule">Schedule</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
