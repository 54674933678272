/*
 * @file
 * Provide key phone numbers
 */

export default function PhoneBar() {
  return (
    <div className="bar phone">
      <div className="contact">Chip: 1-773-350-6027</div>
      <div className="contact">Barbara: 1-919-414- 3652</div>
      <div className="contact">Kevin: 1-202-309-9582</div>
    </div>
  );
}
