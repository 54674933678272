/*
 * @file
 * Create an analog clock
 */

import Clock from "./Clock.js";
import DayOfWeekBar from "components/calendar/DayOfWeekBar";

function ClockCard() {
  return (
    <div className="clock-card card">
      <div className="card-title">
        <DayOfWeekBar />
      </div>
      <div className="card-body">
        <Clock />
      </div>
    </div>
  );
}

export default ClockCard;
