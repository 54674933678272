/*
 * Declare global constants
 */

export const constants = {
  events: {
    apiEndpoint:
      "https://utilities.saplingadvisors.com/events/api/events-for-date.php",
    paginationInterval: 8000, // milliseconds (i.e, 8 seconds)
    refreshEventsInterval: 60000, // milliseconds (i.e., 1 minute)
  },
  photo: {
    apiEndpoint:
      "https://matriarch-photo-frame.saplingadvisors.com/api/random-url.php",
    photoDuration: 30000, // milliseconds
  },
};
