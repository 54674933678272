import "./css/style.css";
import { runAtSpecificTimeOfDay } from "utils/utils.js";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Import pages
import Home from "components/page/Home.js";
import FourCardPage from "components/page/FourCardPage.js";
import TwoCardPage from "components/page/TwoCardPage.js";
import OneCardPage from "components/page/OneCardPage";

// Import cards
import CalendarCard from "components/calendar/CalendarCard";
import ClockCard from "components/clock/ClockCard";
import MatriarchScheduleCard from "components/events/MatriarchScheduleCard.js";
import MppEventsCard from "components/events/MppEventsCard.js";
import PhotoCard from "components/photos/PhotoCard";

export const DateContext = createContext(new Date());

export default function App() {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Update currentDate once per day at 12:10am
  useEffect(() => {
    runAtSpecificTimeOfDay(0, 10, () => setCurrentDate(new Date()));
  });

  return (
    <DateContext.Provider value={currentDate}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/four-card-page" element={<FourCardPage />} />
          <Route path="/two-card-page" element={<TwoCardPage />} />
          <Route
            path="/one-card-page/calendar"
            element={<OneCardPage CardComponent={CalendarCard} />}
          />
          <Route
            path="/one-card-page/clock"
            element={<OneCardPage CardComponent={ClockCard} />}
          />
          <Route
            path="/one-card-page/events"
            element={<OneCardPage CardComponent={MppEventsCard} />}
          />
          <Route
            path="/one-card-page/schedule"
            element={<OneCardPage CardComponent={MatriarchScheduleCard} />}
          />
          <Route
            path="/one-card-page/photos"
            element={<OneCardPage CardComponent={PhotoCard} />}
          />
        </Routes>
      </Router>
    </DateContext.Provider>
  );
}
