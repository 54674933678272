/*
 * @file
 * Provide a simple span containing the day of the week
 */

import React, { useContext } from "react";
import { DateContext } from "App.js";

function DayOfWeekBar() {
  const date = useContext(DateContext);
  const dayOfWeek = React.useRef(null);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Return the name of the current day
  function currentDay() {
    return daysOfWeek[date.getDay()];
  }

  return (
    <div className="bar day-of-week" ref={dayOfWeek}>
      {currentDay()}
    </div>
  );
}

export default DayOfWeekBar;
