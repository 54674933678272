/*
 * @file
 * Provide a simple span containing the day of the week
 */

import React, { useContext } from "react";
import { DateContext } from "App.js";

export default function DayOfWeekWithYearBar() {
  const date = useContext(DateContext);
  const dayOfWeek = React.useRef(null);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Return the name of the current day
  function currentDay() {
    return daysOfWeek[date.getDay()];
  }

  // Return a string for the year
  function currentYear() {
    return date.toLocaleString("en-CA", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }

  return (
    <div className="bar day-of-week-with-year">
      <span ref={dayOfWeek}>{`${currentDay()}, ${currentYear()}`}</span>
    </div>
  );
}
