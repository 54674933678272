/*
 *
 */

import React, { useState, useEffect, useContext } from "react";
import { DateContext } from "App.js";
import Event from "./Event.js";
import { constants } from "constants/constants.js";

export default function Events({ eventsPerPage, fetchEvents, displayRoom }) {
  const [events, setEvents] = useState([]),
    [currentPage, setCurrentPage] = useState(0),
    date = useContext(DateContext),
    paginationInterval = constants.events.paginationInterval;

  useEffect(() => {
    async function loadEvents() {
      setEvents(await fetchEvents());
    }
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  // Implement pagination. This also recolors events as a good side effect.
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentPage(
        (currentPage + 1) % Math.ceil(events.length / eventsPerPage)
      );
    }, paginationInterval);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, events.length]);

  const eventsToShow = events.slice(
    currentPage * eventsPerPage,
    (currentPage + 1) * eventsPerPage
  );

  const eventItems = eventsToShow.map((event) => (
    <Event key={event.id} event={event} displayRoom={displayRoom} />
  ));

  return (
    <table className="table-primary events">
      <EventHeader displayRoom={displayRoom} />
      <tbody>{eventItems}</tbody>
    </table>
  );

  function EventHeader({ displayRoom }) {
    return (
      <thead>
        <tr>
          <th scope="col" className="time">
            Time
          </th>
          <th scope="col" className="description">
            Description
          </th>
          {displayRoom && (
            <th scope="col" className="room">
              Room
            </th>
          )}
        </tr>
      </thead>
    );
  }
}
