/*
 * @file
 * Create an analog clock
 */

import Events from "./Events.js";
import AutoRefreshWrapper from "components/misc/AutoRefreshWrapper.js";
import { constants } from "constants/constants.js";

export default function EventsCard({
  title,
  eventsPerPage,
  fetchEvents,
  displayRoom,
}) {
  const refreshEventsInterval = constants.events.refreshEventsInterval;

  return (
    <div className="events-card card">
      <div className="card-title">
        <div className="bar title">{title}</div>
      </div>
      <div className="card-body">
        <AutoRefreshWrapper refreshInterval={refreshEventsInterval}>
          <Events
            eventsPerPage={eventsPerPage}
            fetchEvents={fetchEvents}
            displayRoom={displayRoom}
          />
        </AutoRefreshWrapper>
      </div>
    </div>
  );
}
