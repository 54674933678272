import MppEventsCard from "components/events/MppEventsCard.js";
import MatriarchScheduleCard from "components/events/MatriarchScheduleCard.js";
import DayOfWeekWithYearBar from "components/calendar/DayOfWeekWithYearBar";
import PhoneBar from "components/misc/PhoneBar";

export default function TwoCardPage() {
  return (
    <div className="mpp-today two-card-page">
      <div className="container">
        <div className="title">
          <DayOfWeekWithYearBar />
        </div>
        <div className="row">
          <div className="col">
            <MatriarchScheduleCard eventsPerPage="15" />
          </div>
          <div className="col">
            <MppEventsCard eventsPerPage="15" />
          </div>
        </div>
        <div className="footer">
          <PhoneBar />
        </div>
      </div>
    </div>
  );
}
