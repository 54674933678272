/*
 * @file
 * Create an analog clock
 */

import EventsCard from "./EventsCard.js";

export default function MatriarchScheduleCard({ eventsPerPage = 15 }) {
  return (
    <EventsCard
      title="Schedule"
      eventsPerPage={eventsPerPage}
      fetchEvents={fetchEvents}
      displayRoom={false}
    />
  );

  async function fetchEvents() {
    const schedule = await fetchSchedule();
    const dayOfWeek = new Date().getDay();

    // Filter the events that don't occur today
    const filteredSchedule = schedule.filter((event) =>
      isDayIncluded(event, dayOfWeek)
    );
    // Update the time on the remaining events
    const events = filteredSchedule.map(updateEventTime);
    return events;
  }

  async function fetchSchedule() {
    const filename = "/schedule/matriarch-schedule.json";

    try {
      const response = await fetch(filename);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("Error fetching schedule:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching schedule:", error.message);
    }
  }

  function isDayIncluded(event, dayOfWeek) {
    // If the key doesn't exist, the default is "true"
    if (!event.hasOwnProperty("days")) {
      return true;
    }

    // Otherwise, return whether dayOfWeek is in the array
    return event.days.includes(dayOfWeek);
  }

  function updateEventTime(event) {
    event.time = addDateToTime(event.time);
    return event;
  }

  // Schedule events use "HH:MM:SS". We want "YYY-MM-DD HH:MM:SS"
  function addDateToTime(timeString) {
    // Get the current date
    const currentDate = new Date();

    // Format the date as YYYY-MM-DD
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Combine the date and time
    const dateTimeString = `${year}-${month}-${day} ${timeString}`;

    return dateTimeString;
  }
}
