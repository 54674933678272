/*
 * @file
 * Create a calendar card
 */

import DayOfYearBar from "./DayOfYearBar.js";
import Calendar from "./Calendar.js";

function CalendarCard() {
  return (
    <div className="calendar-card card">
      <div className="card-title">
        <DayOfYearBar />
      </div>
      <div className="card-body">
        <Calendar />
      </div>
    </div>
  );
}

export default CalendarCard;
