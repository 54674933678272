import ClockCard from "components/clock/ClockCard.js";
import CalendarCard from "components/calendar/CalendarCard.js";
import MppEventsCard from "components/events/MppEventsCard.js";
import PhotoCard from "components/photos/PhotoCard.js";

export default function FourCardPage() {
  return (
    <div className="mpp-today four-card-page">
      <div className="card-container">
        <ClockCard />
        <CalendarCard />
        <MppEventsCard eventsPerPage="5" />
        <PhotoCard />
      </div>
    </div>
  );
}
