/*
 *
 */

export default function Event({ event, displayRoom }) {
  const status = eventStatus(new Date(), new Date(event.time));

  return (
    <tr className={"event " + status}>
      <td className="time">{formatTime(event.time)}</td>
      <td className="description">{event.description}</td>
      {displayRoom && <td className="room">{event.room}</td>}
    </tr>
  );

  function eventStatus(currentTime, eventTime) {
    // difference in minutes
    const timeDifference = (eventTime - currentTime) / (1000 * 60);
    let status = "";

    if (timeDifference >= 30) {
      status = "future";
    } else if (timeDifference <= -30) {
      status = "past";
    } else {
      status = "current";
    }

    return status;
  }

  function formatTime(inputTime) {
    const date = new Date(inputTime);
    return date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
  }
}
