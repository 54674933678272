/*
 * @file
 * Create an analog clock
 */

import React, { useContext } from "react";
import EventsCard from "./EventsCard.js";

import { DateContext } from "App.js";

import { constants } from "constants/constants.js";

export default function MppEventsCard({ eventsPerPage = 15 }) {
  const date = useContext(DateContext);

  return (
    <EventsCard
      title="Today at MPP"
      eventsPerPage={eventsPerPage}
      fetchEvents={fetchEvents}
      displayRoom={true}
    />
  );

  async function fetchEvents() {
    const apiEndpoint = constants.events.apiEndpoint;
    const url = apiEndpoint + "?date=" + date.toISOString().split("T")[0];

    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("Error fetching events:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching events:", error.message);
    }
  }
}
